<template>
  <LiefengContent>
    <template #title>通知记录</template>
    <template #toolsbarRight>
      <Form :label-colon="true" :inline="true">
        <FormItem label="姓名" :label-width="50" style="margin-bottom: 0">
          <Input v-model.trim="name" placeholder="请输入姓名" style="width: 200px"></Input>
        </FormItem>
      </Form>
      <Button type="primary" icon="ios-search" @click="search" style="margin-right: 10px">查询</Button>
      <Button type="success" @click="reset" icon="ios-refresh" style="margin-right: 10px">重置</Button>
      <Button
        type="primary"
        style="margin-right: 10px"
        icon="ios-paper-plane"
        @click="openSendmsg"
      >发社区通知</Button>
      <Button type="primary" icon="ios-create-outline" @click="moreRemarks">批量备注</Button>
      <!-- <Button type="primary" @click="importExcel" icon="ios-add-circle-outline">导入</Button> -->
      <Button
        type="error"
        icon="ios-arrow-back"
        @click="
          $router.push({
            path: $route.query.path,
            query: { menuId: $route.query.menuId },
          })
        "
        :style="{
          display: !$route.query.id && $route.query.columnCode ? 'none' : '',
        }"
      >返回</Button>
    </template>
    <template #contentArea>
      <LiefengTable
        :talbeColumns="talbeColumns"
        :tableData="tableData"
        :loading="loading"
        :fixTable="true"
        :curPage="page"
        :total="total"
        :pagesizeOpts="[20, 30, 50, 100]"
        :page-size="pageSize"
        @hadlePageSize="hadlePageSize"
        @tableSelect="tableSelect"
      ></LiefengTable>

      <!-- 发社区通知 -->
      <LiefengModal
        title="社区通知"
        :value="sendmsgStatus"
        @input="sendmsgStatusFn"
        width="700px"
        height="400px"
      >
        <template #contentarea>
          <Form :model="sendmsgForm" :rules="sendmsgValidate" :label-width="80" ref="sendmsgForm">
            <FormItem label="通知内容" prop="content">
              <Input
                v-model.trim="sendmsgForm.content"
                type="textarea"
                :maxlength="20"
                show-word-limit
                :rows="4"
                placeholder="请填写通知内容，限20字"
              />
            </FormItem>
            <FormItem label="提示说明" prop="remark">
              <Input
                v-model.trim="sendmsgForm.remark"
                :maxlength="20"
                show-word-limit
                placeholder="提示用户下一步操作,限20字"
              />
            </FormItem>
          </Form>
        </template>
        <template #toolsbar>
          <Button
            type="info"
            @click="
              cancelModelTip = true;
              sendmsgStatusFn(false);
            "
            style="margin-right: 10px"
          >取消</Button>
          <Button type="primary" @click="saveSendMsg" :loading="sendmsgLoading">
            <span v-if="!sendmsgLoading">发送</span>
            <span v-else>发送中...</span>
          </Button>
        </template>
      </LiefengModal>

      <!-- 备注 -->
      <LiefengModal title="备注" :value="remarksStatus" @input="remarksStatusFn">
        <template #contentarea>
          <Form
            :model="remarksForm"
            ref="remarksForm"
            :rules="validateRemarks"
            :label-width="90"
            :label-colon="true"
          >
            <FormItem label="备注状态" prop="state">
              <Select v-model="remarksForm.state" transfer>
                <Option value="1">已通知</Option>
                <Option value="2">已预约</Option>
                <Option value="3">已完成</Option>
                <Option value="4">不需要了</Option>
                <Option value="5">已取消</Option>
                <Option value="6">其他</Option>
              </Select>
            </FormItem>
            <FormItem label="备注">
              <Input v-model="remarksForm.remark" type="textarea" placeholder="请填写备注" />
            </FormItem>
          </Form>
        </template>
        <template #toolsbar>
          <Button type="info" @click="remarksStatus = false" style="margin-right: 10px">取消</Button>
          <Button type="primary" @click="saveRemark">确定</Button>
        </template>
      </LiefengModal>

      <!--导入-->

      <!-- <LiefengModal title="导入" :value="excelStatus" @input="exportModalFn">
        <template v-slot:contentarea>
          <Upload
            ref="excelUpload"
            type="drag"
            :action="excelAction"
            name="file"
            :max-size="10240"
            :show-upload-list="true"
            :before-upload="beforeExcelUpload"
            :on-success="myExcel"
            :data="uploadData"
            :headers="uploadHeaders"
            :default-file-list="fileList"
            :format="['xls', 'xlsx', 'xlsb', 'xlsm', 'xlst']"
            :on-format-error="uploadFormatError"
            :on-exceeded-size="uploadSizeError"
          >
            <div style="padding: 20px 0; text-align: center; width: 100%">
              <Icon type="ios-cloud-upload" size="52" style="color: #3399ff"></Icon>
              <p>点击上传，或将文件拖拽到此处</p>
            </div>
          </Upload>
          <div v-if="textStatus">当前选择的文件名称:{{ excelFile.name }}</div>
          <div v-html="errorContent"></div>
          <div style="text-align: center; margin: 20px 0">
            <Button type="primary" style="margin-right: 20px" @click="dowunModal">模板下载</Button>
            <Button type="info" @click="submitExcel">确认导入</Button>
          </div>
        </template>
      </LiefengModal>-->
    </template>
  </LiefengContent>
</template>

<script>
//@route('/questionnairerecord');
import LiefengContent from "@/components/LiefengContent";
import LiefengModal from "@/components/LiefengModal";
import LiefengTable from "@/components/LiefengTable";
export default {
  data() {
    return {
      name: "",
      talbeColumns: [
        {
          type: "selection",
          width: 60,
          align: "center"
        },
        {
          title: "所属社区",
          key: "orgName",
          width: 140,
          align: "center"
        },
        {
          title: "姓名",
          key: "userName",
          width: 140,
          align: "center"
        },
        {
          title: "手机号",
          key: "mobile",
          width: 140,
          align: "center"
        },
        {
          title: "提交时间",
          key: "sendTime",
          width: 170,
          align: "center"
        },
        {
          title: "当前状态",
          key: "status",
          width: 140,
          align: "center"
        },
        {
          title: "备注状态",
          key: "state",
          width: 140,
          align: "center"
        },
        {
          title: "备注",
          key: "remark",
          minWidth: 140,
          align: "center"
        },
        {
          title: "操作",
          width: 100,
          align: "center",
          render: (h, params) => {
            return h(
              "Button",
              {
                props: {
                  type: "primary",
                  size: "small"
                },
                on: {
                  click: () => {
                    this.remarksForm.idList = [params.row.id];
                    this.remarksForm.remark = params.row.remark;
                    switch (params.row.state) {
                      case "已通知":
                        this.remarksForm.state = "1";
                        break;
                      case "已预约":
                        this.remarksForm.state = "2";
                        break;
                      case "已完成":
                        this.remarksForm.state = "3";
                        break;
                      case "不需要了":
                        this.remarksForm.state = "4";
                        break;
                      case "已取消":
                        this.remarksForm.state = "5";
                        break;
                      case "其他":
                        this.remarksForm.state = "6";
                        break;
                      default:
                        this.remarksForm.state = "1";
                        break;
                    }
                    this.remarksStatus = true;
                  }
                }
              },
              "备注"
            );
          }
        }
      ],
      tableData: [],
      loading: false,
      page: 1,
      total: 0,
      pageSize: 20,

      // 社区通知
      sendmsgStatus: false,
      sendmsgForm: {
        content: "",
        remark: ""
      },
      sendmsgLoading: false,
      sendmsgValidate: {
        content: [
          { required: true, message: "请填写通知内容", trigger: "blur" }
        ],
        remark: [{ required: true, message: "请填写提示说明", trigger: "blur" }]
      },
      userList: [],
      openSendmsgIndex: 1, //辨别是否第一次点击发社区通知

      //   备注
      remarksStatus: false,
      remarksForm: {
        state: "1",
        idList: [],
        remark: ""
      },
      validateRemarks: {
        state: [
          { required: true, message: "请选择当前状态", trigger: "change" }
        ]
      },
      menuCodeObj: {}
      // //excel导入弹窗
      // excelStatus: false,
      // //excel文件数据
      // excelFile: null,
      // //修改data
      // titleData: {},
      // uploadData: {},
      // uploadHeaders: {},
      // //导入文字显示标识
      // textStatus: false,
      // loading: false,
      // excelAction: "",
      // //excel导入错误信息
      // errorContent: null,
      // fileList: []
    };
  },
  methods: {
    // dowunModal() {
    //   this.$Message.loading({
    //     content: "正在导出，请稍等...",
    //     duration: 0
    //   });
    //   let link = document.createElement("a");
    //   link.href =
    //     window.vue.getProxy()["/datamsg"].target +
    //     `/api/pc/information/appointment/downloadImportRecordTemplate?informationId=${this.$route.query.id}`;
    //   document.body.appendChild(link);
    //   link.click();
    //   document.body.removeChild(link);
    //   this.$Message.destroy();
    //   return;
    //   this.$.ajax({
    //     url:
    //       window.vue.getProxy()["/datamsg"].target +
    //       "/api/pc/information/appointment/downloadImportRecordTemplate",
    //     type: "GET",
    //     xhrFields: { responseType: "arraybuffer" },
    //     headers: {
    //       "Content-Type": "application/x-www-form-urlencoded"
    //     },
    //     data: {
    //       informationId: this.$route.query.id
    //     },
    //     success: res => {
    //       console.log("获取", res);
    //       // if (res) {
    //       //   try {
    //       //     const blob = new Blob([res.data]);
    //       //     var href = window.URL.createObjectURL(blob);
    //       //     let link = document.createElement("a");
    //       //     link.style.display = "none";
    //       //     link.href = href;
    //       //     link.setAttribute("download", "模板下载.xls");
    //       //     document.body.appendChild(link);
    //       //     link.click();
    //       //     document.body.removeChild(link);
    //       //     window.URL.revokeObjectURL(href);
    //       //     this.$Message.destroy();
    //       //   } catch (error) {
    //       //     console.log(error);
    //       //     this.$Message.destroy();
    //       //     this.$Message.error({
    //       //       background: true,
    //       //       content: res.desc
    //       //     });
    //       //   }
    //       // }
    //     },
    //     error: err => {
    //       console.log(err);
    //       this.$Message.error({
    //         background: true,
    //         content: "导出失败，请联系管理员处理"
    //       });
    //     }
    //   });
    // },
    // exportModalFn(status) {
    //   this.excelStatus = status;
    // },
    // //导入按钮
    // importExcel() {
    //   this.excelFile = null;
    //   this.fileList = [];
    //   this.textStatus = false;
    //   this.excelStatus = true;
    // },

    // //确认导入
    // submitExcel() {
    //   if (this.excelFile) {
    //     this.$refs.excelUpload.post(this.excelFile);
    //   } else {
    //     this.$Message.error({
    //       background: true,
    //       content: "请先选择文件"
    //     });
    //   }
    // },
    // //导入excel格式错误
    // uploadFormatError(file) {
    //   this.$Notice.warning({
    //     title: "文件格式不正确",
    //     desc:
    //       "文件" +
    //       file.name +
    //       "格式不正确，请上传xls,xlsx,xlsb,xlsm或xlst格式的文件"
    //   });
    // },
    // //导入excel文件大小超出限制
    // uploadSizeError(file) {
    //   this.$Notice.error({
    //     title: "导入失败",
    //     desc: "文件 " + file.name + " 大小已超出限制，请控制在10M以内"
    //   });
    // },
    // //excel文件上传成功后执行
    // myExcel(res) {
    //   if (res.code == 200) {
    //     this.$Message.success({
    //       background: true,
    //       content: "导入成功！"
    //     });
    //     this.hadlePageSize({
    //       page: this.page,
    //       pageSize: this.pageSize
    //     });
    //     this.textStatus = false;
    //     this.excelStatus = false;
    //     if (res.dataList.length != 0) {
    //       this.errorContent = "";
    //       res.dataList.map((item, index) => {
    //         this.errorContent += `<div style="color:black">${item}</div>`;
    //       });
    //     }
    //   } else {
    //     this.fileList = [];

    //     this.$Message.error({
    //       background: true,
    //       content: "导入失败,请按照模板格式填写数据！"
    //     });
    //   }
    // },
    // //上传之前
    // beforeExcelUpload(file) {
    //   this.excelAction =
    //     window.vue.getProxy()["/old"].target +
    //     "/api/pc/information/appointment/importRecord";
    //   this.uploadData.orgCode = parent.vue.loginInfo.userinfo.orgCode;
    //   this.uploadData.custGlobalId = parent.vue.loginInfo.userinfo.custGlobalId;
    //   this.uploadData.informationId = this.$route.query.id;
    //   this.uploadHeaders = {
    //     openId: window.sessionStorage.getItem("openId")
    //   };
    //   this.excelFile = file;
    //   this.textStatus = true;
    //   return false;
    // },
    // 批量备注
    moreRemarks() {
      if (this.userList.length == 0) {
        this.$Modal.info({
          title: "温馨提示",
          content: "请从表格中勾选需要备注的数据"
        });
        return;
      }
      this.remarksForm.state = "1";
      this.remarksForm.idList = [];
      // 如果只勾选了一条数据，回显数据
      if (this.userList.length == 1) {
        this.tableData.map(item => {
          if (item.id == this.userList[0].id) {
            this.remarksForm.remark = item.remark;
            switch (item.state) {
              case "已通知":
                this.remarksForm.state = "1";
                break;
              case "已预约":
                this.remarksForm.state = "2";
                break;
              case "已完成":
                this.remarksForm.state = "3";
                break;
              case "不需要了":
                this.remarksForm.state = "4";
                break;
              case "已取消":
                this.remarksForm.state = "5";
                break;
              case "其他":
                this.remarksForm.state = "6";
                break;
              default:
                this.remarksForm.state = "1";
            }
          }
        });
      }
      this.userList.map(item => {
        this.remarksForm.idList.push(item.id);
      });
      this.remarksStatus = true;
    },
    //   备注
    saveRemark() {
      this.$refs.remarksForm.validate(status => {
        if (status) {
          this.$post("/datamsg/api/pc/information/v2/remarkMessage", {
            idList: this.remarksForm.idList.join(","),
            remark: this.remarksForm.remark,
            state: this.remarksForm.state
          })
            .then(res => {
              if (res.code == 200) {
                this.$Message.success({
                  background: true,
                  content: "备注成功"
                });
                this.hadlePageSize({
                  page: this.page,
                  pageSize: this.pageSize
                });
                this.remarksStatus = false;
              } else {
                this.$Message.error({
                  background: true,
                  content: "备注失败，请重试！"
                });
              }
            })
            .catch(err => {
              console.log(err);
              this.$Message.error({
                background: true,
                content: "备注失败，请联系管理员处理"
              });
            });
        }
      });
    },
    remarksStatusFn(status) {
      if (!status) {
        this.remarksForm.remark = "";
      }
      this.remarksStatus = status;
    },
    search() {
      this.hadlePageSize({
        page: 1,
        pageSize: this.pageSize
      });
    },
    reset() {
      this.name = "";
      this.hadlePageSize({
        page: 1,
        pageSize: this.pageSize
      });
    },
    hadlePageSize(obj) {
      this.loading = true;
      this.$get("/datamsg/api/pc/information/v2/selectMessagePage", {
        informationId: this.$route.query.id,
        // orgCode: parent.vue.loginInfo.userinfo.orgCode,
        page: obj.page,
        pageSize: obj.pageSize,
        name: this.name
      })
        .then(res => {
          if (res.code == 200) {
            this.loading = false;
            this.tableData = res.dataList.map(item => {
              this.userList.map((subItem) => {
                if (subItem.id == item.id) {
                  item._checked = true;
                }
              });
              switch (item.status) {
                case "0":
                  item.status = "已通知";
                  break;
                case "wait":
                  item.status = "等待发送";
                  break;
                default:
                  item.status = "发送失败";
                  break;
              }
              switch (item.state) {
                case "1":
                  item.state = "已通知";
                  break;
                case "2":
                  item.state = "已预约";
                  break;
                case "3":
                  item.state = "已完成";
                  break;
                case "4":
                  item.state = "不需要了";
                  break;
                case "5":
                  item.state = "已取消";
                  break;
                case "6":
                  item.state = "其他";
                  break;
              }
              if (item.sendTime) {
                item.sendTime = this.$core.formatDate(
                  new Date(item.sendTime),
                  "yyyy-MM-dd hh:mm:ss"
                );
              }
              return item;
            });
            this.page = res.currentPage;
            this.pageSize = res.pageSize;
            this.total = res.maxCount;
          } else {
            this.loading = false;
            this.$Message.error({
              background: true,
              content: res.desc
            });
          }
        })
        .catch(err => {
          console.log(err);
          this.loading = false;
          this.$Message.error({
            background: true,
            content: "获取数据失败，请联系管理员处理"
          });
        });
    },
    // 发社区通知
    openSendmsg() {
      if (this.openSendmsgIndex == 1) {
        this.$Modal.info({
          title: "温馨提示",
          onOk: () => {
            this.openSendmsgIndex = 2;
            this.sendmsgStatus = true;
          },
          content:
            "默认给所有填写人发送社区通知，若只需给指定填写人发送，请从表格中勾选数据再发！"
        });
      } else {
        this.sendmsgStatus = true;
      }
    },
    saveSendMsg() {
      this.$refs.sendmsgForm.validate(status => {
        if (status) {
          this.sendmsgLoading = true;
          this.$post(
            "/gateway/syinfopublish/api/pc/information/question/pushMessageCustomize",
            {
              functionType: this.menuCodeObj.functionType,
              content: this.sendmsgForm.content,
              remark: this.sendmsgForm.remark,
              userList: (() => {
                let arr = this.userList.map(item => {
                  return {
                    custGlobalId: item.custGlobalId,
                    orgCode: item.orgCode
                  };
                });
                return arr.length > 0 ? arr : null;
              })(),
              informationId: this.$route.query.id,
              oemCode: parent.vue.oemInfo.oemCode,
              operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
              startDate: this.startDate ? this.startDate : null,
              endDate: this.endDate ? this.endDate : null
            },
            { "Content-Type": "application/json" }
          )
            .then(res => {
              if (res.code == 200) {
                this.sendmsgLoading = false;
                this.$Message.success({
                  background: true,
                  content: "发送成功"
                });
                this.cancelModelTip = false;
                this.sendmsgStatus = false;
                this.userList = [];
                this.hadlePageSize({
                  page: this.page,
                  pageSize: this.pageSize
                });
              } else {
                this.sendmsgLoading = false;
                this.$Message.error({
                  background: true,
                  content: res.desc
                });
              }
            })
            .catch(err => {
              this.sendmsgLoading = false;
              this.$Message.error({
                background: true,
                content: "发送失败"
              });
            });
        }
      });
    },
    tableSelect(arr) {
      let userList = [];

        this.userList.map(item => {
          if(item.page != this.page) {
            userList.push(item);
          }
        })
        this.userList = userList;
      arr.map(item => {
        let custGlobalId = "";
        if (item.custGlobalId) {
          this.userList.push({
            custGlobalId: item.custGlobalId,
            orgCode: item.orgCode,
            id: item.id,
            page: this.page
          });
        } else {
          this.userList.push({
            custGlobalId: item.agentCustGlobalId,
            orgCode: item.orgCode,
            id: item.id,
            page: this.page
          });
        }
      });
      this.userList = this.$core.onlyList(this.userList, "custGlobalId", "id");
    },
    sendmsgStatusFn(status) {
      if (!status && this.cancelModelTip) {
        this.$Modal.confirm({
          title: "温馨提示",
          content: "<p>确认要关闭窗口吗？未保存的信息不会保存</p>",
          onOk: () => {
            this.sendmsgStatus = status;
          }
        });
      } else {
        this.sendmsgStatus = status;
        this.$refs.sendmsgForm.resetFields();
      }
    }
  },
  created() {
    this.hadlePageSize({
      pageSize: this.pageSize,
      page: this.page
    });

    // 获取functionType/columnCode等
    this.$get("/old/api/pc/menu/selectMenuByMenuCode", {
      orgCode: parent.vue.loginInfo.userinfo.orgCode,
      menuCode: this.$core.getUrlParam("menuId")
    }).then(res => {
      if (res.code == 200 && res.data) {
        this.menuCodeObj = res.data;
      } else {
        this.$Message.error({
          background: true,
          content: res.desc
        });
      }
    });
  },
  components: {
    LiefengContent,
    LiefengModal,
    LiefengTable
  }
};
</script>
    
<style scoped lang='less'>
</style>